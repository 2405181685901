// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Almarai&display=swap');
.table-responsive {
  height: 35rem;
  overflow-y: scroll;
}

.who-style {
  color: #468dc9;
  vertical-align: bottom;
  font-size: 20px;
  padding-right: 1.2%
}
